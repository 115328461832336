export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        title: "Pick Students"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        title: "学生选择"
    }
}