export default {
    en: {
        tagAdmit: "Admitted",
        tagNotAdmit: "Not Admitted",
        buttonAdmit: "Admit",
        buttonAdmitCancel: "Revoke",
        buttonPayRecord: "Payment Records",
        buttonRemoveStudent: "Remove Student",
        buttonOpenForm: "Open Form",
        buttonStudent: "Classes",
        infoDay: "DOB"
    },
    zh: {
        tagAdmit: "已录取",
        tagNotAdmit: "等待录取",
        buttonAdmit: "录取",
        buttonAdmitCancel: "取消录取",
        buttonPayRecord: "缴费记录",
        buttonRemoveStudent: "移除学生",
        buttonOpenForm: "打开资料",
        buttonStudent: "学生课程",
        infoDay: "生日"
    }
}