<template>
<van-cell-group class="shadow-md" inset>
    <van-cell center>
        <template #title>
            <van-image class="cursor-pointer" :src="Env.userPath + student.icon" fit="cover" :radius="10" width="4rem" height="4rem" @click="clickIcon(student)" />
        </template>
        <template #value>
            <div class="flex flex-col items-end">
                <van-tag class="mb-2" v-if="!student.valid" size="medium" type="danger">{{lang[langCode].tagNotAdmit}}</van-tag>
                <van-tag class="mb-2" v-if="student.valid" size="medium" type="success">{{lang[langCode].tagAdmit}}</van-tag>
            </div>
        </template>
    </van-cell>
    <van-cell>
        <template #title>
            <div class="flex justify-items-start items-start">
                <div class="flex flex-col gap-2">
                    <span class="pb-1">{{student.name}}</span>
                    <span class="text-gray-400 text-xs">{{student.first}} {{student.last}}</span>
                    <span class="text-gray-400 text-xs">{{lang[langCode].infoDay}} {{day}}</span>
                </div>
            </div>
        </template>
        <template #value>
            <div class="flex flex-col items-end">
                <van-button v-if="!student.valid" plain type="success" size="small" @click="take()">{{lang[langCode].buttonAdmit}}</van-button>
                <van-button v-if="student.valid" plain type="warning" size="small" @click="reject()">{{lang[langCode].buttonAdmitCancel}}</van-button>
                <van-button class="mt-2" plain type="primary" size="small" @click="checkTrans(student.id)">{{lang[langCode].buttonPayRecord}}</van-button>
                <van-button class="mt-2" plain type="danger" size="small" @click="remove()">{{lang[langCode].buttonRemoveStudent}}</van-button>
                <van-button class="mt-2" plain type="primary" size="small" @click="manage()">{{lang[langCode].buttonStudent}}</van-button>
            </div>
        </template>
    </van-cell>
    <van-cell v-if="!student.seeForm && student.form">
        <template #title>
            <van-button class="mt-2" plain type="primary" size="small" @click="seeForm(student)">{{lang[langCode].buttonOpenForm}}</van-button>
        </template>
    </van-cell>
    <div v-if="student.form && student.seeForm">
        <van-cell v-for="(item, index) in student.form" :key="index" :title="item.zh" :value="item.in" />
    </div>
</van-cell-group>
</template>

<script>
import lang from "@/langs/StudentListCardLang.js";
import {
    Tag,
    Cell,
    CellGroup,
    Image as VanImage,
    Button
} from "vant";
import Env from "@/logics/Envs.js";
import {
    computed
} from "vue";

export default {
    components: {
        [Tag.name]: Tag,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [VanImage.name]: VanImage,
        [Button.name]: Button
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
        student: Object
    },
    setup(props, {
        emit
    }) {
        const clickIcon = function (student) {
            emit("showProfile", student);
        };

        const take = function () {
            emit("changeValid", true);
        };

        const reject = function () {
            emit("changeValid", false);
        };

        const checkTrans = function (studentId) {
            emit("checkTrans", studentId);
        };

        const remove = function () {
            emit("remove");
        };

        const seeForm = function (student) {
            student.seeForm = true;
        };

        const day = computed(function () {
            if (!props.student.dob) {
                return "xxxx";
            }
            return props.student.dob.split("-")[0] + "-XX-XX";
        });

        const manage = function () {
            emit("manageStudent");
        };

        return {
            lang,
            Env,
            clickIcon,
            take,
            reject,
            checkTrans,
            remove,
            seeForm,
            day,
            manage
        };
    }
}
</script>
