export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        buttonAddStudent: "Add Student",
        buttonDownloadForm: "Download Enroll Forms",
        textNoStudents: "No Students",
        removeTitle: "Remove Student",
        removeMessage: "Are you sure to remove student [@@] from this class?",
        removeSuccess: "Student Is Removed",
        studentSetSuccess: "Set Student Success",
        studentSetFail: "Set Student Fail"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        buttonAddStudent: "添加报名",
        buttonDownloadForm: "下载学生资料",
        textNoStudents: "未有学生报名",
        removeTitle: "移除学生",
        removeMessage: "确定把学生 [@@] 移出此课程吗？",
        removeSuccess: "学生已退出课程",
        studentSetSuccess: "学生录取设定成功",
        studentSetFail: "学生录取设定失败"
    }
}