<template>
    <div class="pt-32 flex flex-wrap" v-if="user">
        <div class="md:w-1/2 w-full mb-12">
            <class-details-card v-if="classDetails" :class-details="classDetails" :lang-code="langCode" :my-theme="myTheme"
                ref="card" />
            <div class="my-8 flex flex-col px-4">
                <div class="flex flex-row">
                    <van-button class="w-1/2 m-2" type="primary" @click="studentPicker()">{{ lang[langCode].buttonAddStudent
                    }}</van-button>
                    <van-button class="w-1/2 m-2" type="primary" @click="downloadStudents()">{{
                        lang[langCode].buttonDownloadForm }}</van-button>
                </div>
                <div class="flex flex-row">
                    <van-button class="w-1/2 m-2" type="primary" @click="downloadPhotos()">下载学生照片</van-button>
                    <van-button class="w-1/2 m-2" type="primary" @click="goTransactionList()"
                        v-if="isManager">查看支付报表</van-button>
                </div>
            </div>
        </div>
        <div class="md:w-1/2 w-full">
            <van-empty v-if="!studentList.length" :description="lang[langCode].textNoStudents" />
            <div v-if="studentList.length" class="flex flex-wrap justify-center">
                <student-list-card class="mb-4 w-80" v-for="(student, index) in studentList" :key="student.id"
                    :student="student" @change-valid="changeValid($event, student)" @show-profile="showProfile"
                    @check-trans="checkTrans" @remove="removeStudent(student, index)" :lang-code="langCode"
                    @manage-student="goManage(student.id)" />
            </div>
        </div>
        <student-picker ref="studentPickerRef" :only-active="true" @finish="gotStudents" :lang-code="langCode" />
        <profile-viewer ref="viewProfileRef" :lang-code="langCode" :showSystemTag="true" />
    </div>
</template>

<script>
import lang from "@/langs/PageClassStudentsLang.js";
import Env from "@/logics/Envs.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Button,
    Empty,
    Toast,
    Dialog
} from "vant";
import UserTypes from "@/static_values/StaticUserTypes.js";
import ClassDetailsCard from "@/components/ClassDetailsCard.vue";
import StudentListCard from "@/components/list/StudentListCard.vue";
import ProfileViewer from "@/components/dialog/ProfileViewer.vue";
import myTheme from "@/logics/MyVantTheme.js";
import LoadClass from "@/asyncs/LoadClass.js";
import GetSession from "@/asyncs/GetSession.js";
import StudentPicker from "@/components/dialog/StudentPicker.vue";
import UpdateStudents from "@/asyncs/UpdateClassStudents.js";
import AdmitStudent from "@/asyncs/AdmitStudent.js";
import QuitClass from "@/asyncs/QuitClass.js";
import LoadStudents from "@/asyncs/LoadStudents.js";

export default {
    components: {
        [Button.name]: Button,
        [Empty.name]: Empty,
        ClassDetailsCard,
        StudentListCard,
        StudentPicker,
        ProfileViewer
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const route = useRoute();
        const router = useRouter();
        const classDetails = ref(null);
        const studentList = ref([]);
        const card = ref(null)
        let students = null;
        let studentsTotal = 0;
        let studentsAdmit = 0;
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                if (!store.state.classDetails) {
                    classDetails.value = await LoadClass(route.params.classId);
                    if (!classDetails.value) {
                        return;
                    }
                } else {
                    classDetails.value = store.state.classDetails;
                }
                store.commit("setPageTitle", classDetails.value.className);
                students = await LoadStudents(route.params.classId);
                if (!students) {
                    return;
                }
                Object.values(students).forEach(function (s) {
                    studentList.value.push(s);
                    studentsTotal += 1;
                    if (s.valid) {
                        studentsAdmit += 1;
                    }
                });
                card.value.showText(studentsAdmit + "/" + studentsTotal);
            })
        });

        const changeValid = async function (value, student) {
            const error = await AdmitStudent(classDetails.value.classId, student.id, value);
            if (error) {
                Toast.fail(lang[store.state.lang].studentSetFail + ": " + error);
                console.log(error);
            } else {
                student.valid = value;
                Toast.success(lang[store.state.lang].studentSetSuccess);
            }
        };

        const studentPickerRef = ref(null);
        const studentPicker = function () {
            studentPickerRef.value.showPop();
        };
        const gotStudents = async function (pickedStudents) {
            //Overwrite some student information
            pickedStudents.forEach(function (student) {
                if (student.id in students) {
                    student.form = students[student.id].form;
                    students[student.id] = student;
                    return;
                }
                students[student.id] = student;
                studentList.value.push(student);
            });

            const error = await UpdateStudents(classDetails.value.classId, pickedStudents);
            if (error) {
                Toast.fail(lang[store.state.lang].studentSetFail);
                console.log(error);
            } else {
                Toast.success(lang[store.state.lang].studentSetSuccess);
            }
        };

        const viewProfileRef = ref(null);
        const showProfile = function (studentProfile) {
            const profile = {};
            profile.firstName = studentProfile.first;
            profile.lastName = studentProfile.last;
            profile.showName = studentProfile.name;
            profile.dob = studentProfile.dob;
            profile.gender = studentProfile.gender;
            profile.icon = studentProfile.icon;
            const meta = {
                tags: {}
            };
            if (studentProfile.tags) {
                meta.tags.personal = studentProfile.tags.personal;
                meta.tags.system = studentProfile.tags.system
            }
            viewProfileRef.value.showUser({
                userId: studentProfile.id,
                userType: UserTypes.student.value,
                profile,
                meta
            });
        };

        const checkTrans = function (studentId) {
            router.push("/studentDetails/" + studentId + "/pay/" + classDetails.value.classId);
        };

        const removeStudent = async function (student, index) {
            try {
                await Dialog.confirm({
                    title: lang[store.state.lang].removeTitle,
                    message: lang[store.state.lang].removeMessage.replace("@@", student.name),
                    confirmButtonColor: "#ee0a24",
                    confirmButtonText: lang[store.state.lang].confirm,
                    cancelButtonText: lang[store.state.lang].cancel
                });
            } catch (e) {
                console.log(e);
                return;
            }

            const ok = await QuitClass(classDetails.value.classId,
                classDetails.value.className,
                student.id, student.name,
                store.state.user.userId);
            if (ok) {
                Toast.success(lang[store.state.lang].removeSuccess);
                studentList.value.splice(index, 1);
            }
        };

        const downloadStudents = function () {
            let text = "";
            if (classDetails.value.form) {
                text += "\"ID\",\"Name\",";
                classDetails.value.form.forEach(function (item) {
                    text += "\"" + item.zh + "\","
                });
                text += "\n";
                studentList.value.forEach(function (student) {
                    text += "\"" + student.id + "\","
                    text += "\"" + student.name + "\","
                    student.form.forEach(function (item) {
                        if (item.in) {
                            text += "\"" + item.in + "\","
                        } else {
                            text += "\"\","
                        }
                    });
                    text += "\n";
                });
                saveText(text, "students.csv");
            } else {
                alert("No form");
            }
        };

        const saveText = function (text, fileName) {
            const blob = new Blob([text], {
                type: "text/plain;charset=utf-8"
            });

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
            a.remove();
        };

        const downloadPhotos = async function () {
            // const waitingToast = Toast.loading({
            //     message: "建立下载中，请耐心等待",
            //     forbidClick: true,
            //     duration: 0
            // });

            studentList.value.forEach(async function (studentItem) {
                const photoUrl = Env.userPath + studentItem.icon;
                window.open(photoUrl, "_blank");
                // const res = await fetch(photoUrl);
                // const blob = await res.blob();
                // const a = document.createElement("a");
                // document.body.appendChild(a);
                // a.style = "display: none";

                // const url = URL.createObjectURL(blob);
                // a.href = url;
                // const downloadName = studentItem.id + "_" + studentItem.first + "_" + studentItem.last + "." + studentItem.icon.split(".")[1];
                // a.download = downloadName
                // a.click();
                // URL.revokeObjectURL(url);
                // a.remove();
            });

            // setTimeout(function () {
            //     waitingToast.clear();
            // }, 2000);
        };

        const goManage = function (id) {
            router.push("/studentClass/" + id);
        };
        const goTransactionList = function () {
            router.push("/transactionRecords/" + route.params.classId);
        };

        return {
            lang,
            langCode: computed(() => store.state.lang),
            myTheme,
            classDetails,
            studentList,
            changeValid,
            studentPickerRef,
            studentPicker,
            gotStudents,
            viewProfileRef,
            showProfile,
            checkTrans,
            removeStudent,
            user: computed(() => store.state.user),
            card,
            downloadStudents,
            downloadPhotos,
            goManage,
            goTransactionList,
            isManager: computed(() => UserTypes.manager.value === store.state.user.userType),
        };
    }
}
</script>
