<template>
<van-dialog v-model:show="show" :title="lang[langCode].title" show-cancel-button @confirm="confirm()" :confirm-button-color="'#ee0a24'" :confirm-button-text="lang[langCode].confirm" :cancel-button-text="lang[langCode].cancel">
    <van-divider />
    <div class="flex flex-col overflow-y-scroll my-max-h">
        <div v-for="(student, index) in students" :key="index" class="flex mb-7 px-3">
            <van-image width="6rem" height="6rem" fit="cover" :radius="10" :src="Env.userPath + student.icon" class="mr-3 cursor-pointer" :class="{'picked': student.picked, 'not-picked': !student.picked}" @click="pick(student)" />
            <div class="w-56">
                <span class="font-bold mb-2 block" :class="{'text-green': student.picked}">{{student.name}}</span>
                <span class="mb-2 block text-xs text-gray-400">{{student.first}} {{student.last}}, {{student.dob}}</span>
                <van-tag class="mr-3 mb-1" :color="TagTypes.personal.color" v-for="(tag, index) in student.tags.personal" :key="index">{{tag}}</van-tag>
                <van-tag class="mr-3 mb-1" :color="TagTypes.system.color" v-for="(tag, index) in student.tags.system" :key="index">{{tag}}</van-tag>
            </div>
        </div>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/StudentPickerLang.js";
import {
    ref,
    onMounted
} from "vue";
import {
    Dialog,
    Image as VanImage,
    Tag,
    Divider
} from "vant";
import ListStudent from "@/asyncs/ListStudent.js";
import Env from "@/logics/Envs.js";
import TagTypes from "@/static_values/StaticTagTypes.js";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [VanImage.name]: VanImage,
        [Tag.name]: Tag,
        [Divider.name]: Divider
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
        onlyActive: Boolean
    },
    setup(props, {
        emit
    }) {
        const students = ref([]);
        onMounted(async function () {
            const studentList = await ListStudent(null, null, props.onlyActive);
            if (!studentList) {
                return;
            }
            students.value = studentList.map(function (a) {
                return {
                    id: a.userId,
                    first: a.profile.firstName,
                    last: a.profile.lastName,
                    name: a.profile.showName,
                    icon: a.profile.icon,
                    dob: a.profile.dob,
                    gender: a.profile.gender,
                    tags: a.meta.tags
                };
            });
        });
        const show = ref(false);
        const showPop = function () {
            show.value = true;
            students.value.forEach(function (student) {
                delete student.picked;
            });
        };
        const confirm = function () {
            const chosen = [];
            students.value.forEach(function (student) {
                if (student.picked) {
                    student.valid = false;
                    delete student.picked;
                    chosen.push(student);
                }
            });
            emit("finish", chosen);
        };
        const pick = function (student) {
            student.picked = !student.picked;
        };
        return {
            lang,
            Env,
            TagTypes,
            show,
            students,
            showPop,
            pick,
            confirm
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
}
</style>
